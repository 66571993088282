<template>
    <div :style="getElementStyle">
    <el-row v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
    </el-row>
    <div>
      <div class="robot-box">
      <label class="ml-1 checkbox-label">
        <input
          type="checkbox"
          v-model="isNotARobot"
          @change="handleCheckboxChange"
          class="custom-checkbox"
          :disabled="isCaptchaVerified"
        />
        I am not a robot
        <div class="recaptcha-img">
          <img src="@/assets/img/icons/recaptcha.svg" alt="icon" width="50" height="45" />
        </div>
      </label>
    </div>
    <!-- <div v-if="isLoading" class="mt-1 ml-1">
      <div class="loading-indicator">
        <i class="el-icon-loading"></i>
        Verifying...
      </div>
    </div>
    <div v-else-if="isNotARobot && !isCaptchaVerified" class="mt-1">
      <div>
        <el-col class="captcha-box" :span="18">
          <span
            v-for="(char, index) in captcha"
            :key="index"
            :style="getCharStyles(index)"
          >{{ char }}</span>
        </el-col>
         <el-button type="text" @click="generateCaptcha" class="ml-1">
            <i class="el-icon-refresh" style="font-size: 30px; font-weight: 600;"></i>
          </el-button>
      </div>
    </div> -->
    </div>
    <div v-if="!isDefalutPosq">
      <!-- <div style="display: flex; align-items: center;">
        <label for="text" style="white-space: nowrap; margin-right: 5px;">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >*</span>
        <span v-if="data.description" style="margin-right: 5px;">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
        <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"></el-input>
      </div>
      <div>
        <span v-if="isActive || isDataTableField" class="setting-icon">
          <i class="el-icon-s-tools" @click="openSettings"></i>
        </span>
      </div> -->
    </div>
    <!-- <span style="display: none">{{ isDefalutPosq }}</span> -->
    <!-- Below code is for Top Field View -->

    <el-row align="middle" v-else>
      <!-- <el-col
        :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
        v-if="isNotARobot && !isLoading && !isCaptchaVerified"
      >
        <label for="text">{{ data.label }}</label>
        <span v-if="data.validations.required" style="color: red; font-weight: bold">*</span>
        <span v-if="data.description">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
      </el-col> -->

      <el-col :span="showLabel && isDefalutPosq ? 4 : 0">
        <!-- <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>-->
      </el-col>

      <el-col
        :span="showLabel
        &&
        !isDefalutPosq
        ?
        12
        :
        24"
      >
        <!-- <div>
          <el-input
            :style="getStyle"
            :placeholder="data.placeholder"
            v-model="selectedValue"
            v-if="isNotARobot && !isLoading && !isCaptchaVerified"
          ></el-input>
           <div v-if="verificationLoading" class="mt-1 ml-1">
              <i class="el-icon-loading"></i>
               Verifying...
            </div>
          <div v-if="isCaptchaVerified && !verificationLoading" style="color: green; margin-top:2px;">
            <h4>Verified<i class="el-icon-success"></i></h4>
          </div>
          <p v-if="notMatched && !isCaptchaVerified && !verificationLoading && selectedValue.length >= 6" style="color: red;">
            Captcha is not matched, please re-enter the new captcha</p>
        </div> -->
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";

import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "CaptchaView",
  // components: {
  //   VueRecaptcha
  // },
  props: ["data", "value", "isActive", "isDataTableField"],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),

    // selectedValue: {
    //   get() {
    //     return this.value;
    //   },
    //   set(value) {
    //     this.$emit("input", value);
    //   }
    // },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }
      inputStyle += ";";

      // inputStyle = inputStyle + `width: ${this.data.width}px !important;`;
      return inputStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    },
    getElementStyle() {
      let borderStyle = "";

      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    }
  },
  created() {
    this.generateCaptcha();
  },
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      showLabel: true,
      captcha: "",
      isNotARobot: false,
      isLoading: false,
      verificationLoading: false,
      isCaptchaVerified: false,
      notMatched: false,
      isRobotVerified: false,
      selectedValue: ""
    };
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if (this.data.styles.labelStyle == "right") {
        //   this.isDefalutPos = false;
        // }
      }
    }

    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  methods: {
    checkCaptcha() {
      if (this.captcha.length == this.selectedValue.length) {
        this.verificationLoading = true;
        setTimeout(() => {
          this.verificationLoading = false;
        }, 2000);
        this.isCaptchaVerified = this.selectedValue === this.captcha;
        this.$set(this.form,this.data.key,true);
      }
      if (this.selectedValue !== this.captcha) {
        this.notMatched = true;
        this.$set(this.form,this.data.key,false);
      }  
    },
    shuffledBorderColors(clonedColors) {
      for (let i = clonedColors.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [clonedColors[i], clonedColors[j]] = [clonedColors[j], clonedColors[i]];
      }
      return clonedColors;
    },
    getCharStyles(index) {
      let colors = [
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#ffff00",
        "#ff00ff",
        "#00ffff"
      ];
      let borderColors = [
        "#ff00ff",
        "#b103fc",
        "#00ffff",
        "#03dbfc",
        "#ff0000",
        "#ffff00",
        "#0000ff",
        "#00ff00",
      ];
      // colors = this.shuffledBorderColors(colors);
      // borderColors = this.shuffledBorderColors(borderColors);
      return {
        color: colors[index % colors.length],
        textStroke: `1px ${borderColors[index % borderColors.length]}`,
        marginLeft: "7px",
        marginTop: "2px",
        display: "inline-block"
      };
    },
    generateCaptcha() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const captchaLength = 6;
      let captcha = "";

      for (let i = 0; i < captchaLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        captcha += characters[randomIndex];
      }

      this.captcha = captcha;
      return this.captcha;
    },
    onRecaptchaVerify(response) {
      console.log("Recaptcha verified:", response);
      // Do something with the verified response
    },
    onRecaptchaExpired() {
      // Handle expired state if needed
    },
    handleCheckboxChange() {
      if (this.isNotARobot) {
        this.isLoading = true;
          
        setTimeout(() => {
          this.isLoading = false;
          this.isRobotVerified = true;
        }, 2000);
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "SINGLE_LINE_TEXT") {
          // this.isList = true;
          this.data.selectedValue = globalVariable.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
      }
    }
  }
};
</script>
  
<style lang="scss" scoped>
.el-input__inner {
  height: inherit !important;
}
.captcha-box {
  width: 160px;
  height: 47px;
  border: 1px solid #3498db;
  background-color: #626565;
  font-size: 25px;
  font-weight: 555;
}
.loading-indicator {
  display: flex;
  align-items: left;
  justify-content: left;
}

.loading-indicator i {
  margin-right: 8px;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.checkbox-label {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
}
.robot-box {
  width: 310px;
  height: 73px;
  background: #e2e8eb;
}
.recaptcha-img {
  margin-left: 78px;
}
</style>